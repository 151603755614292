<template>
  <!--Date Picker-->

  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="true"
    :return-value.sync="date"
    transition="scale-transition"
    min-width="290px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        :value="dateValue"
        :label="date_label"
        append-icon="mdi-calendar"
        readonly
        v-bind="attrs"
        v-on="on"
        dense
        outlined
        color="#3d2cdd"
      ></v-text-field>
    </template>
    <v-date-picker
      v-model="dateValue"
      @change="passDate"
      no-title
      scrollable
      color="#3d2cdd"
      show-adjacent-months
    >
      <v-spacer></v-spacer>
      <v-btn text color="#ce2458" @click="menu = false"> Cancel </v-btn>
      <v-btn text color="#33cc99" @click="$refs.menu.save(date)"> OK </v-btn>
    </v-date-picker>
  </v-menu>
</template>
<script>
export default {
  name: "InvoiceDatePicker",
  props: ["date_label", "default_date"],
  data() {
    return {
      menu: null,
      date: null,
      dateValue: this.default_date,
    };
  },
  created() {
    this.$emit("changeDate", this.dateValue);
  },
  methods: {
    passDate() {
      this.$emit("changeDate", this.dateValue);
    },
  },
};
</script>